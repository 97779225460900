import moment from "moment";
import { format } from "date-fns";

export default {
  data() {
    // TODO: Find a way to make dates dynamic
    return {
      weekOneStartDates: {
        2022: new Date("January 3, 2022"),
        2023: new Date("December 30, 2022"),
        2024: new Date("December 29, 2023"),
        2025: new Date("December 27, 2024"),
        // Add more years as needed
      },
      weekOneStartDatesForPicks: {
        2022: new Date("January 3, 2022"),
        2023: new Date("December 28, 2022"),
        2024: new Date("December 27, 2023"),
        2025: new Date("January 1, 2025"),
        // Add more years as needed
      }
    };
  },
  methods: {
    NumberOfWeeks(start, today) {
      const days = today.diff(start, "days");

      const weeks = days / 7;
      return Math.ceil(weeks);
    },
    GetWeek(date) {
      const selected = moment(date);
      const selectedFormat = moment(date).format("YYYY-MM-DD");
      if (moment(selectedFormat).isBetween("2021-12-29", "2022-12-27")) {
        return this.NumberOfWeeks(moment([2021, 11, 29]), selected);
      } else if (moment(selectedFormat).isBetween("2022-12-28", "2023-12-27")) {
        return this.NumberOfWeeks(moment([2022, 11, 28]), selected);
      } else if (moment(selectedFormat).isBetween("2023-12-28", "2024-12-26")) {
        return this.NumberOfWeeks(moment([2023, 11, 28]), selected);
      } else if (moment(selectedFormat).isSameOrAfter("2024-12-27")) {
        // TODO: Might need further validation
        return this.NumberOfWeeks(moment([2024, 11, 27]), selected) + 1;
      } else {
        return moment(date).format("ww");
      }
    },
    getWeekNumber(date) {
      const year = date.getFullYear();
      const weekOneStartDate = this.weekOneStartDates[year];
      if (!weekOneStartDate) {
        return null; // Invalid year
      }

      // Calculate the number of days between the date and the start of the year
      const timeDiff = date.getTime() - weekOneStartDate.getTime();
      const daysSinceWeekOneStart = Math.floor(timeDiff / (1000 * 3600 * 24));

      // Calculate the week number (week one starts on the custom start date)
      return Math.ceil((daysSinceWeekOneStart + 1) / 7);
    },
    getPicksWeekNumber(date) {
      const year = date.getFullYear();
      const weekOneStartDate = this.weekOneStartDatesForPicks[year];
      if (!weekOneStartDate) {
        return null; // Invalid year
      }

      // Calculate the number of days between the date and the start of the year
      const timeDiff = date.getTime() - weekOneStartDate.getTime();
      const daysSinceWeekOneStart = Math.floor(timeDiff / (1000 * 3600 * 24));

      if (year === 2025) {
        return Math.ceil((daysSinceWeekOneStart + 1) / 7) + 1;
      } else {
        // Calculate the week number (week one starts on the custom start date)
        return Math.ceil((daysSinceWeekOneStart + 1) / 7);
      }
    },
    getDatesOfWeek(date) {
      const year = date.getFullYear();
      const weekNumber = this.getWeekNumber(date);
      const weekOneStartDate = this.weekOneStartDates[year];
      if (!weekOneStartDate) {
        return null; // Invalid year
      }

      // Calculate the start date of the given week number (week one starts on the custom start date)
      const daysToAdd = (weekNumber - 1) * 7;
      const startDate = new Date(
        weekOneStartDate.getTime() + daysToAdd * 24 * 3600 * 1000
      );

      const endDate = new Date(
        new Date(startDate).getTime() + 6 * 24 * 60 * 60 * 1000
      );

      const startFormatted = format(startDate, "E, do MMM");
      const endFormatted = format(endDate, "E, do MMM");

      return startFormatted + " - " + endFormatted;
    }
  }
};
